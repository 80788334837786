li.sended-like-view-item {
    float: left;
    min-height: 0px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    border-radius: 20px;

}

img.sended-like-view-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

div.sended-like-view-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: start;
    align-items: end;
    padding: 20px;
    box-sizing: border-box;
}

p.sended-like-view-user-info-txt-p {
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    font-weight: bold;
    color: #fff;
    font-family: 'SF Pro Display', sans-serif;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}