div.likes-view-content {
    height: 100%;
}

ul.likes-view-listing-ul {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
    overflow-y: auto;
    padding: 0;
    gap: 10px;
    box-sizing: border-box;
    margin: 0;
}