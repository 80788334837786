li.chat-match-item {
    float: left;
}

div.chat-match-inner-view-div {
    width: 120px;
    height: 150px;
    display: relative;
    border-radius: 10px;
    overflow: hidden;
}

img.chat-match-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

p.chat-match-txt-p {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #000;
    overflow: hidden;
    font-family: 'SF Pro Display', sans-serif;
    margin-top: 5px;
}