nav.main-bottom-nav {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: black;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 1000;
}

ul.main-bottom-nav-listing-ul {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
}

li.main-bottom-nav-li {
    list-style-type: none;
}

li.main-bottom-nav-li.active .main-bottom-nav-icon {
    color: #df0a0a;
}

.main-bottom-nav-icon {
    font-size: 2.5rem;
    color: rgb(146, 146, 146);
}