div#main-layout-div {
    max-width: 600px;
    width: 100%;
    height: 100dvh;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    background-color: #1b1b1b;
}

div.main-content-div {
    width: 100%;
    height: calc(100dvh - 70px);
    overflow: hidden;
    box-sizing: border-box;
}