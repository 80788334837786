li.profile-list {
    float: left;
    width: 100%;
    display: relative;
    border-radius: 20px;
    overflow: hidden;
    background-color: #000000;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

li.profile-list:last-child {
    margin-bottom: 20px;
}

li.profile-list-top-li {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
}

.main-profile-list-icon {
    font-size: 2rem;
    color: #d3d3d3;
    float: left;
}

p.profile-list-title {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    font-family: 'SF Pro Display', sans-serif;
    margin-left: 10px;
    float: left;
}

p.profile-list-bio-txt-p {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    color: #ffffff;
    font-family: 'SF Pro Display', sans-serif;
    margin-top: 10px;
}