li.likes-view-item {
    float: left;
}

div.likes-inner-view-div {
    width: 120px;
    height: 150px;
    border-radius: 15px;
    overflow: hidden;
    border: 5px solid rgb(0, 0, 0);
    box-sizing: border-box;
}

img.likes-view-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    border: 5px solid white;
    box-sizing: border-box;
}

p.likes-view-txt-p {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #000;
    overflow: hidden;
    font-family: 'SF Pro Display', sans-serif;
    margin-top: 5px;
}