div.user-profile-view {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

div.user-profile-top-area {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

div.user-profile-img-div {
    width: 180px;
    height: 180px;
    border-radius: 100px;
    overflow: hidden;
}

img.user-profile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

div.user-info-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
}

p.user-name {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
    font-family: 'SF Pro Display', sans-serif;
}