nav.main-profile-top-nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: #000000;
    z-index: 1000;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

ul.main-profile-top-nav-listing-ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    padding: 0 20px;
    box-sizing: border-box;
}

li.main-profile-nav-name-li {
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

p.main-profile-nav-name {
    font-size: 1.6rem;
    font-family: 'SF Pro Display', sans-serif;
    color: white;
    font-weight: bold;
}

span.profile-age {
}

li.main-profile-nav-quick-btn-li {
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

button.main-profile-nav-quick-btn {
    background-color: rgb(184, 0, 240);
    background-image: linear-gradient(45deg, rgb(0, 108, 247), rgb(255, 10, 10));
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: #000;
    padding: 5px 10px;
    border-radius: 5px;
    float: right;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}

button.main-profile-nav-quick-btn:hover {
    background-image: linear-gradient(45deg, rgb(0, 108, 247), rgb(255, 10, 10));
}

.main-profile-nav-quick-btn-icon {
    font-size: 1rem;
    color: #fff;
}

div.main-profile-view {
    width: 100%;
    height: calc(100% - 70px);
    margin-top: 70px;
    box-sizing: border-box;
    background-color: rgb(28, 28, 28);
    overflow: auto;
}

ul.main-profile-info-listing-ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    padding: 0 10px;
}