li.profile-list-item-li {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
    box-sizing: border-box;
}

.profile-list-item-icon {
    font-size: 1.5rem;
    color: #d3d3d3;
    float: left;
}

p.profile-list-item-value {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    color: #ffffff;
    font-family: 'SF Pro Display', sans-serif;
    margin-left: 10px;
    float: left;
}