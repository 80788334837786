.swipe-card {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    scroll-snap-align: start; /* Snap to the start of each card */
}

.swipe-card-content {
    width: 100%;
    height: 100%;
    position: relative;
}

.swipe-card-nav {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    cursor: pointer;
    z-index: 1;
}

.swipe-card-nav.left {
    left: 0;
}

.swipe-card-nav.right {
    right: 0;
}

div.swipe-card-image-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    overflow-x: auto;
}

div.swipe-card-swiper {
    width: 100%;
    height: 100%;
}

img.swipe-card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    float: left;
}

div.swipe-card-overlay-div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

div.swipe-card-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

div.swipe-card-top-area {
    width: 100%;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 300;
}

div.swipe-card-img-stepper {
    width: 100%;
    height: 70px;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    gap: 5px;
}

div.swipe-card-img-stepper-dot {
    width: 20px;
    height: 5px;
    background-color: #a3a3a3;
    border-radius: 5px;
}

div.swipe-card-img-stepper-dot.active {
    background-color: #ffffff;
}

div.swipe-card-bottom-area {
    width: 100%;
    min-height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 20px;
    color: white;
    display: flex;
    background-color: transparent;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    box-sizing: border-box;
    z-index: 200;
}

div.swipe-card-bottom-wrapper {
    width: 100%;
    min-height: inherit;
    padding-bottom: 25px;
}

div.swipe-card-bottom-labels-area {
    width: 100%;
    min-height: 0px;
    display: flex;
    margin-bottom: 10px;
}

div.swipe-card-bottom-label {
    height: 30px;
    min-width: 50px;
    padding: 0 20px;
    background-color: rgb(19, 141, 19);
    background-image: linear-gradient(45deg, rgb(0, 247, 29), rgb(0, 175, 12));
    overflow: hidden;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

p.swipe-card-bottom-label-txt-p {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
    display: inline-block;
    font-weight: bold;
}

div.swipe-card-bottom-info {
    width: 100%;
    min-height: 10px;
}

div.swipe-card-bottom-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.swipe-card-bottom-left-area {}

div.swipe-card-bottom-name-age {
    width: 100%;
    display: flex;
    align-items: center;
}

h2.swipe-card-name {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    float: left;
}

.swipe-card-verified-icon {
    font-size: 2rem;
    float: left;
    color: rgb(0, 119, 255);
    margin-left: 10px;
}

p.swipe-card-age {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    float: left;
    margin-left: 20px;
}

p.swipe-card-bottom-distance-txt-p {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
    display: inline-block;
}

div.swipe-card-bottom-right-area {}

button.swipe-card-bottom-btn {
    background-color: rgb(184, 0, 240);
    background-image: linear-gradient(45deg, rgb(0, 108, 247), rgb(255, 10, 10));
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: #000;
    padding: 5px 10px;
    border-radius: 5px;
    float: right;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}

.swipe-card-bottom-btn-icon {
    font-size: 4rem;
    color: white;
}

div.swipe-card-bottom-additional-info {
    width: 100%;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-top: 10px;
}

.swipe-card-location-icon {
    font-size: 1.5rem;
    float: left;
    margin-right: 10px
}

div.swipe-card-bottom-controls-area {
    width: 100%;
    height: 0;
}

.like-heart {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 100px;
    color: red;
    animation: heartAnimation 1s ease-in-out;
    z-index: 1000;
}

@keyframes heartAnimation {
    0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
    }
    50% {
        transform: translate(-50%, -50%) scale(1.2);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
}

.hearts-container {
    position: absolute;
    pointer-events: none;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.big-heart {
    font-size: 100px;
    position: absolute;
    animation: fadeOut 1s forwards;
    z-index: 100;
}

.small-heart {
    font-size: 24px;
    position: absolute;
    animation: fadeOut 1s forwards;
}

.small-heart-1 {
    top: 0px;
    left: 0px2;
}

.small-heart-2 {
    top: -20px;
    right: -80px;
}

.small-heart-3 {
    bottom: -20px;
    left: -20px;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(2);
    }
}

.swiper-pagination {
    position: absolute;
    bottom: unset !important;
    top: 30px !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
} 

.swiper-pagination-bullet {
    width: 20px !important;
    height: 5px !important;
    background-color: #525252 !important;
    border-radius: 5px !important;
}

.swiper-pagination-bullet-active {
    background-color: #ffffff !important;
}