div.main-chats-view {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow: auto;
}

div.main-chats-matches-wrapper {
    padding: 10px;

}

h3.main-chats-matches-txt-h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #000;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}

ul.main-chats-matches-listing-ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    overflow: auto;
}

div.main-chats-wrapper-div {
    width: 100%;
    box-sizing: border-box;
}

h3.main-chats-txt-h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #000;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    padding: 0 10px;

}

ul.main-chats-listing-ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}