li.chat-item {
    float: left;
    width: 100%;
    min-height: 0px;
    overflow: hidden;
    cursor: pointer;
    padding: 15px 10px;
    display: flex;
    gap: 20px;
    align-items: center;
    box-sizing: border-box;
}

li.chat-item:hover {
    background-color: #f5f5f5;
}

img.chat-item-img {
    width: 90px;
    height: 90px;
    border-radius: 50px;
    object-fit: cover;
}

div.chat-message-area {}

div.chat-message-sender-area {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

p.chat-message-sender-txt-p {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: #000;
    font-family: 'SF Pro Display', sans-serif;
}