div.swipe-list-area {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
    scroll-snap-type: y mandatory;
}

div.profile-page {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 1000;
}