div.profile-card {
    width: 100%;
    height: 80%;
    position: relative;
}

img.profile-card-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
}

div.profile-card-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    box-sizing: border-box;
}

div.profile-card-bottom-area {
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 300;
}

div.profile-card-img-stepper {
    width: 100%;
    height: 30px;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    gap: 5px;
}

div.profile-card-img-stepper-dot {
    width: 100%;
    height: 5px;
    background-color: #a3a3a3;
    border-radius: 5px;
}

div.profile-card-img-stepper-dot.active {
    background-color: #ffffff;
}
