
@font-face {
  font-family: 'SF Pro Display';
  src: url('assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
      url('assets/fonts/SFProDisplay-Regular.woff') format('woff'),
      url('assets/fonts/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


html, body {
  /* height: 100vh; */
  height: 100dvh;
}

div#root {
  height: 100dvh;
  display: flex;
  flex-direction: column;
}