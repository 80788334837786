div.main-matches-view {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

div.main-matches-tabs-bar {
    width: 100%;
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    background-color: #000000;
    padding-bottom: 20px;
    padding-top: 20px;
    
}

button.main-matches-tab-btn {
    width: 100%;
    height: 100%;
    background-color: #000000;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 600;
    color: #ffffff;
    font-weight: bold;
    border-right: 1px solid #ccc;
}

button.main-matches-tab-btn:last-child {
    border-right: none;
}

button.main-matches-tab-btn.active {
    color: #df0a0a;
    font-weight: bold;
}

div.main-matches-content {
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    box-sizing: border-box;
}