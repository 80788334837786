nav.main-top-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 1000;
    display: flex;
}

ul.main-top-nav-listing-ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
    padding: 0 10px;
}

li.main-top-nav-logo-li {
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

button.main-top-nav-profile-btn {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    overflow: hidden;
    padding: 0;
    margin: 0;
    border: 4px solid darkblue;
}

img.main-top-nav-profile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

div.main-matching-percentage-div {
    font-family: 'SF Pro Display', sans-serif;
    background-color: darkblue;
    background-image: linear-gradient(45deg, rgb(0, 108, 247), rgb(255, 10, 10));
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-size: 1.5rem;
    padding: 5px 10px;
    font-weight: bold;
}

img.main-top-nav-logo-img {
    height: 30px;
    margin-left: 10px;
}

li.main-top-nav-quick-btns-li {
    list-style-type: none;
    width: 140px;
}

ul.main-top-nav-quick-btns-listing-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li.main-top-nav-quick-btn-li {
    list-style-type: none;
}

button.main-top-nav-quick-btn {
    background-color: #262626;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: #000;
    padding: 5px 10px;
    border-radius: 5px;
    float: left;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-top-nav-quick-btn-icon {
    font-size: 2rem;
    color: rgb(146, 146, 146);
}